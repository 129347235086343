
import { defineComponent } from 'vue';
import Header from '@/components/layout/header.vue';

export default defineComponent({
  name: 'App',
  components: {
    Header,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1500);
  },
});
