import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass({
      'bg-light-taupe items-center flex shadow-xl ease-in-out transition': true,
      'h-0': !_ctx.active,
      'h-24	': _ctx.active,
      active: _ctx.active,
    })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'container text-center md:text-right': true,
        hidden: !_ctx.active,
      })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: index,
          to: { path: '/', hash: `#${item.urlHash}` },
          class: _normalizeClass({
          'text-base font-normal uppercase': true,
          'mx-5 px-5': item.space,
        })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.title), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"]))
      }), 128))
    ], 2)
  ], 2))
}