
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UiButton',
  props: {
    link: {
      type: String,
    },
    classes: {
      type: [String, Array, Object],
    },
    title: {
      type: String,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
});
