
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LayoutHeader',
  data() {
    return {
      active: false,
    };
  },
  computed: {
    navItems() {
      return [
        {
          urlHash: 'about',
          title: 'About',
          space: false,
        },
        {
          urlHash: 'projects',
          title: 'Projects',
          space: true,
        },
        {
          urlHash: 'contact',
          title: 'Contact',
          space: false,
        },
      ];
    },
  },
  mounted() {
    window.document.onscroll = () => {
      if (window.scrollY > 100) this.active = true;
      else this.active = false;
    };
  },
});
